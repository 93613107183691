<template>
  <div>
    <base-header class="pb-6" type="primary">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Dashboard</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
          </nav>
        </b-col>
      </b-row>
      <!-- Card stats -->
      <b-row v-for="data in today" :key="data.operation">
        <b-col xl="12" md="12">
          <h4 class="text-white">{{data.operation}} OP</h4>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Total packages"
                      type="gradient-orange"
                      :sub-title="Number(data.total_items).toLocaleString()"
                      icon="ni ni-chart-pie-35">
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Normal"
                      type="gradient-info"
                      :sub-title="Number(data.total_normal).toLocaleString()"
                      icon="fa fa-motorcycle">
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Total Bus"
                      type="gradient-red"
                      :sub-title="Number(data.total_bus).toLocaleString()"
                      icon="fa fa-bus">

          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Total Taxi"
                      type="gradient-green"
                      :sub-title="Number(data.total_taxi).toLocaleString()"
                      icon="fa fa-taxi">

          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Total Pending"
                      type="gradient-green"
                      :sub-title="Number(data.total_pending).toLocaleString()"
                      icon="fa fa-taxi">

          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Total Return"
                      type="gradient-green"
                      :sub-title="Number(data.total_returned).toLocaleString()"
                      icon="fa fa-taxi">

          </stats-card>
        </b-col>
        <!-- <b-col xl="3" md="6">
          <stats-card title="Total $"
                      type="gradient-green"
                      :sub-title="Number(data.total_price_usd).toLocaleString()"
                      icon="ni ni-money-coins">

          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Total ៛"
                      type="gradient-green"
                      :sub-title="Number(data.total_price_riel).toLocaleString()"
                      icon="ni ni-money-coins">

          </stats-card>
        </b-col> -->
        <b-col xl="3" md="6">
          <stats-card title="Total Profit $"
                      type="gradient-green"
                      :sub-title="(Number(data.total_profit_usd) + (Number(data.total_profit_riel) / 4000)).toLocaleString()"
                      icon="ni ni-money-coins">

          </stats-card>
        </b-col>
        <!-- <b-col xl="3" md="6">
          <stats-card title="Total Profit ៛"
                      type="gradient-green"
                      :sub-title="Number(data.total_profit_riel).toLocaleString()"
                      icon="ni ni-money-coins">

          </stats-card>
        </b-col> -->
        <b-col xl="3" md="6">
          <stats-card title="Total Delivery $"
                      type="gradient-green"
                      :sub-title="(Number(data.total_delivery_fee_usd) + (Number(data.total_delivery_fee_riel) / 4000)).toLocaleString()"
                      icon="ni ni-money-coins">

          </stats-card>
        </b-col>
        <!-- <b-col xl="3" md="6">
          <stats-card title="Total Delivery Fee ៛"
                      type="gradient-green"
                      :sub-title="Number(today.total_delivery_fee_riel).toLocaleString()"
                      icon="ni ni-money-coins">

          </stats-card>
        </b-col> -->
      </b-row>

      <hr class="bg-white">
      <b-row>
        <b-col sm="3">
              <label class="form-control-label text-white"> Date</label>
              <base-input addon-left-icon="ni ni-calendar-grid-58" class="mt-0">
                  <flat-picker
                        :config="{allowInput: true}"
                        class="form-control datepicker"
                        v-model="filter.date">
                  </flat-picker>
              </base-input>
        </b-col>
        <b-col sm="9"></b-col>
        <template v-if="shouldShow">
        <b-row v-for="data in others" :key="data.operation">
        <b-col xl="12" md="12">
          <h4 class="text-white">{{data.operation}} OP</h4>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Total packages"
                      type="gradient-orange"
                      :sub-title="Number(data.total_items).toLocaleString()"
                      icon="ni ni-chart-pie-35">
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Normal"
                      type="gradient-info"
                      :sub-title="Number(data.total_normal).toLocaleString()"
                      icon="fa fa-motorcycle">
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Total Bus"
                      type="gradient-red"
                      :sub-title="Number(data.total_bus).toLocaleString()"
                      icon="fa fa-bus">

          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Total Taxi"
                      type="gradient-green"
                      :sub-title="Number(data.total_taxi).toLocaleString()"
                      icon="fa fa-taxi">

          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Total Pending"
                      type="gradient-green"
                      :sub-title="Number(data.total_pending).toLocaleString()"
                      icon="fa fa-taxi">

          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Total Return"
                      type="gradient-green"
                      :sub-title="Number(data.total_returned).toLocaleString()"
                      icon="fa fa-taxi">

          </stats-card>
        </b-col>
        <!-- <b-col xl="3" md="6">
          <stats-card title="Total $"
                      type="gradient-green"
                      :sub-title="Number(data.total_price_usd).toLocaleString()"
                      icon="ni ni-money-coins">

          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Total ៛"
                      type="gradient-green"
                      :sub-title="Number(data.total_price_riel).toLocaleString()"
                      icon="ni ni-money-coins">

          </stats-card>
        </b-col> -->
        <b-col xl="3" md="6">
          <stats-card title="Total Profit $"
                      type="gradient-green"
                      :sub-title="(Number(data.total_profit_usd) + (Number(data.total_profit_riel) / 4000)).toLocaleString()"
                      icon="ni ni-money-coins">

          </stats-card>
        </b-col>
        <!-- <b-col xl="3" md="6">
          <stats-card title="Total Profit ៛"
                      type="gradient-green"
                      :sub-title="Number(data.total_profit_riel).toLocaleString()"
                      icon="ni ni-money-coins">

          </stats-card>
        </b-col> -->
        <b-col xl="3" md="6">
          <stats-card title="Total Delivery $"
                      type="gradient-green"
                      :sub-title="(Number(data.total_delivery_fee_usd) + (Number(data.total_delivery_fee_riel) / 4000)).toLocaleString()"
                      icon="ni ni-money-coins">

          </stats-card>
        </b-col>
        <!-- <b-col xl="3" md="6">
          <stats-card title="Total Delivery Fee ៛"
                      type="gradient-green"
                      :sub-title="Number(today.total_delivery_fee_riel).toLocaleString()"
                      icon="ni ni-money-coins">

          </stats-card>
        </b-col> -->
      </b-row>
        <!-- <b-col xl="3" md="6">
          <stats-card title="Total Delivery Fee ៛"
                      type="gradient-green"
                      :sub-title="Number(others.total_delivery_fee_riel).toLocaleString()"
                      icon="ni ni-money-coins">

          </stats-card>
        </b-col> -->
        </template>
      </b-row>
    </base-header>

  </div>
</template>
<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import DashboardRequest from '@requests/dashboard_request';
  export default {
    mounted() {
      DashboardRequest.index().then(({ data }) => {
        this.today = data.today;
      });
    },
    components: {
      flatPicker
    },
    data() {
      return {
        today: [],
        others: [],
        shouldShow: false,
        filter: {
          date: new Date()
        }
      };
    },
    methods: {
      getSummary() {
        DashboardRequest.getData(this.filter).then(({ data }) => {
          this.others = data.other;
          this.shouldShow = true
        });
      }
    },
    watch: {
      'filter.date': function(value) {
        this.getSummary();
      }
     }
  };
</script>
<style></style>
